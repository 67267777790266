import { Heading, LoadingDots, Spacer, Stack, useBreakpointValue } from '@qasa/qds-ui'
import type { FunctionComponent } from 'react'
import { Suspense, lazy, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { useLocalStorage } from '@qasa/app/web'
import { useRegionConfig } from '@qasa/app/src/configs/use-region-config'
import { Error } from '@qasa/app/src/components/error'

import { HideInEmbeddedFindHomeWrapper } from '../../ui-shared/hide-in-embedded-find-home-wrapper'

import { LeftColumn, MainContent, MapContent } from './page-layout'
import { ListOfHomes } from './result-list'
import { HomeListResultText } from './result-list/home-list-result-text'
import { HOME_SEARCH_COORDS } from './api/home-search-coords.gql'
import { filtersToHomeSearchParams, useFormattedTitle } from './find-home.utils'
import { MapToggle } from './map/map-toggle'
import { Sorting } from './filters/sorting'
import { usePersistedMapState } from './map/use-persisted-map-state'
import { LongTermSearch } from './long-term-search'
import { SearchHistoryProvider } from './search-history/search-history-context'
import { useFindHomeFiltersContext } from './context/find-home-filter-context'
import { useFindHomeContext } from './context/find-home-context'
import type { FindHomeNode } from './find-home.types'

const Map = lazy(() =>
  import('./map/map').then(({ Map }) => ({
    default: Map,
  })),
)

type Props = {
  searchComponent?: FunctionComponent
  offeringInfoBoxComponent?: FunctionComponent
}
export function FindHome({
  searchComponent: SearchComponent = LongTermSearch,
  offeringInfoBoxComponent: OfferingInfoBox,
}: Props) {
  const { rentalType, homesError, areas, isLoadingAreas } = useFindHomeContext()
  const [hoveredHome, setHoveredHome] = useState<FindHomeNode | null>(null)
  const isXlOrAbove = useBreakpointValue({ base: false, xl: true })
  const { filterValues } = useFindHomeFiltersContext()
  const { persistedMapState, setPersistedMapState } = usePersistedMapState()
  const [isTwoColumnMapOpen, setIsTwoColumnMapOpen] = useLocalStorage<boolean | null>('defaultMapState', true)
  const [isFullScreenMapOpen, setIsFullScreenMapOpen] = useState(persistedMapState?.isMapOpen || false)
  const isMapOpen = isXlOrAbove ? Boolean(isTwoColumnMapOpen) : isFullScreenMapOpen
  const { market, currency } = useRegionConfig()

  const title = useFormattedTitle({ filterValues, rentalType })
  useEffect(() => {
    /* TODO */
    /* Combine these to both use the same persistant state (ls/cookie), along with the search areas */
    setPersistedMapState({ isMapOpen })
  }, [isMapOpen, setPersistedMapState, setIsTwoColumnMapOpen])

  useEffect(() => {
    if (isMapOpen && !isXlOrAbove) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isMapOpen, isXlOrAbove])

  const {
    error: coordsError,
    data: coordsData,
    loading: isLoadingLocations,
  } = useQuery(HOME_SEARCH_COORDS, {
    variables: {
      filterOnArea: false,
      market,
      searchParams: {
        ...filtersToHomeSearchParams({
          searchParams: filterValues,
          areas,
          rentalType,
        }),
        currency,
      },
    },
    skip: !isMapOpen,
  })

  const handleHoveredHome = ({ home }: { home: FindHomeNode | null }) => setHoveredHome(home)

  if (coordsError && homesError) {
    return <Error error={homesError} />
  }

  return (
    <>
      <Stack direction="row">
        <LeftColumn>
          <MainContent>
            <HideInEmbeddedFindHomeWrapper>
              <Heading size={'md'} as="h1">
                {title}
              </Heading>
              <Spacer size={'6x'} />
            </HideInEmbeddedFindHomeWrapper>
            <div>
              <SearchHistoryProvider rentalType={rentalType}>
                <SearchComponent />
              </SearchHistoryProvider>
              <Spacer size={'12x'} />
              <HideInEmbeddedFindHomeWrapper>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <HomeListResultText />
                  <Sorting />
                </Stack>
              </HideInEmbeddedFindHomeWrapper>
            </div>
            <Spacer size={'2x'} />
            <div>
              {homesError ? (
                <Error error={homesError} />
              ) : (
                <ListOfHomes
                  isMapOpen={isMapOpen}
                  onHoveredHome={handleHoveredHome}
                  offeringInfoBox={OfferingInfoBox}
                />
              )}
            </div>
          </MainContent>
        </LeftColumn>

        <MapContent isMapOpen={isMapOpen}>
          <Sentry.ErrorBoundary>
            {coordsError ? (
              <Error error={coordsError} />
            ) : (
              <Suspense fallback={<LoadingDots />}>
                <Map
                  isMapOpen={isMapOpen}
                  coordsData={coordsData}
                  hoveredHome={hoveredHome}
                  areas={areas}
                  isLoadingLocations={isLoadingLocations}
                  isLoadingPolygons={isLoadingAreas}
                />
              </Suspense>
            )}
          </Sentry.ErrorBoundary>
        </MapContent>
      </Stack>

      <HideInEmbeddedFindHomeWrapper>
        <Spacer size="6x" />
        <MapToggle
          isShowingMap={isMapOpen}
          onClick={() => {
            isXlOrAbove
              ? setIsTwoColumnMapOpen((isOpen) => !isOpen)
              : setIsFullScreenMapOpen((isOpen) => !isOpen)
          }}
        />
      </HideInEmbeddedFindHomeWrapper>
    </>
  )
}
