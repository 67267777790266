import { formatNumber } from '@qasa/app/src/utils/number'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { useFindHomeContext } from '../context/find-home-context'

const ResultsParagraph = styled.p(({ theme }) => ({
  ...theme.typography.label.md,
  paddingLeft: theme.spacing['2x'],
}))

export function HomeListResultText() {
  const { t } = useTranslation('find_home', { keyPrefix: 'list_of_homes' })
  const { homesResultCount, isHomesLoading } = useFindHomeContext()
  const formattedHomeCount = formatNumber({ amount: homesResultCount ?? 0 })
  const context = homesResultCount === 1 ? null : 'plural'

  return (
    <ResultsParagraph>
      {isHomesLoading
        ? t('fetching_homes')
        : t('number_of_results', {
            count: homesResultCount ?? 0,
            formattedHomeCount,
            context,
          })}
    </ResultsParagraph>
  )
}
