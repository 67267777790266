import { Stack, useBreakpointValue } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { useAuthContext } from '../../context/auth-context'
import { useEmbeddedFindHome } from '../../hooks/use-embedded-find-home'
import { HideInEmbeddedFindHomeWrapper } from '../../ui-shared/hide-in-embedded-find-home-wrapper'

import { MoreFiltersDialog } from './filters/more-filters-dialog'
import { CreateSavedSearch } from './saved-searches/create-saved-search'
import { useLongTermFilterSections } from './filters/sections/use-long-term-filter-sections'
import { AreaSearchAutocomplete } from './area-search/area-search-autocomplete'
import { AreaSearchDialog } from './area-search'
import { useFindHomeFiltersContext } from './context/find-home-filter-context'

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  [theme.mediaQueries.mdUp]: {
    maxWidth: 800,
  },
}))

const FlexGrow = styled.div({
  flex: 1,
  minWidth: 0,
})

export function LongTermSearch() {
  const { isAuthenticated } = useAuthContext()
  const {
    filterValues: { searchAreas },
    updateFilterValues,
  } = useFindHomeFiltersContext()
  const filterSections = useLongTermFilterSections()
  const isEmbeddedFindHome = useEmbeddedFindHome()
  const isDesktop = useBreakpointValue({ base: false, md: true })

  return (
    <Wrapper direction="row" gap="2x">
      <FlexGrow>
        {isDesktop ? (
          <AreaSearchAutocomplete
            value={searchAreas}
            isFindHome
            onChange={(searchAreas) => updateFilterValues({ searchAreas })}
          />
        ) : (
          <AreaSearchDialog
            value={searchAreas}
            onChange={(searchAreas) => updateFilterValues({ searchAreas })}
            shouldShowSearchHistory
            shouldAutofocus={!isEmbeddedFindHome}
          />
        )}
      </FlexGrow>
      <MoreFiltersDialog filterSections={filterSections} />
      {isAuthenticated && (
        <HideInEmbeddedFindHomeWrapper>
          <CreateSavedSearch />
        </HideInEmbeddedFindHomeWrapper>
      )}
    </Wrapper>
  )
}
