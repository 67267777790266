/* eslint-disable complexity */
import type {
  HomeIndexSearchQueryQuery,
  HomeSearchOrderByEnum,
  HomeSearchOrderEnum,
  HomeTypeEnum,
  UploadTypeEnum,
} from '@qasa/graphql'
import {
  CurrencyEnum,
  HomeIndexSearchOrderByEnum,
  HomeIndexSearchOrderDirectionEnum,
  HomeRentalTypeEnum,
  TraitTypeEnum,
} from '@qasa/graphql'
import { useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { GET_USER_FAVORITE_HOMES } from '@qasa/app/src/features/find-home/hooks/use-home-search/use-home-search-query.gql'

import type { FindHomeNode } from '../find-home.types'

const homeIndexOrderBy: Record<HomeSearchOrderByEnum, HomeIndexSearchOrderByEnum> = {
  CREATED_AT: HomeIndexSearchOrderByEnum.published_or_bumped_at,
  PUBLISHED_AT: HomeIndexSearchOrderByEnum.published_or_bumped_at,
  UPDATED_AT: HomeIndexSearchOrderByEnum.published_or_bumped_at,
  // TODO: Talk to backend about these
  FAVORITE_MARKED_AT: HomeIndexSearchOrderByEnum.published_or_bumped_at,
  ROUTE: HomeIndexSearchOrderByEnum.published_or_bumped_at,
  // END TODO
  MONTHLY_COST: HomeIndexSearchOrderByEnum.monthly_cost_cents,
  RENT: HomeIndexSearchOrderByEnum.monthly_cost_cents,
  ROOM_COUNT: HomeIndexSearchOrderByEnum.room_count,
  MOVE_IN: HomeIndexSearchOrderByEnum.start_date,
  MOVE_OUT: HomeIndexSearchOrderByEnum.end_date,
  SORTING_SCORE: HomeIndexSearchOrderByEnum.sorting_score,
  SQUARE_METERS: HomeIndexSearchOrderByEnum.square_meters,
}

const homeIndexOrderDirection: Record<HomeSearchOrderEnum, HomeIndexSearchOrderDirectionEnum> = {
  ASCENDING: HomeIndexSearchOrderDirectionEnum.ascending,
  DESCENDING: HomeIndexSearchOrderDirectionEnum.descending,
}

export const useMapIndexSearchToHomeSearch = () => {
  const { data } = useQuery(GET_USER_FAVORITE_HOMES, {
    fetchPolicy: 'network-only',
  })

  const homeDocumentToHomeNode = useCallback(
    (
      homeIndexSearchNode: HomeIndexSearchQueryQuery['homeIndexSearch']['documents']['nodes'][number],
    ): FindHomeNode => {
      const favoriteHomes = data?.favoriteHomes.filterHomesOffset.nodes || []
      const isFavoriteMarkedByUser = favoriteHomes.some(
        (favoriteHome) => favoriteHome.id === homeIndexSearchNode.id,
      )

      return {
        __typename: 'Home',
        averagePricePerNight: null,
        bedCount: null,
        currency: homeIndexSearchNode.currency
          ? CurrencyEnum[homeIndexSearchNode.currency as CurrencyEnum]
          : CurrencyEnum.SEK, // TODO: currency should always be available on the home
        bedroomCount: homeIndexSearchNode.bedroomCount,
        corporateHome: homeIndexSearchNode.corporateHome || false,
        floor: null,
        buildingFloors: null,
        duration: {
          __typename: 'Duration',
          createdAt: null,
          updatedAt: null,
          id: homeIndexSearchNode.id, // NOTE: This is a fake id to make the key unique
          endOptimal: homeIndexSearchNode.endDate,
          endUfn: homeIndexSearchNode.endDate === null,
          startAsap: homeIndexSearchNode.startDate === null,
          startOptimal: homeIndexSearchNode.startDate,
        },
        traits: homeIndexSearchNode.furnished ? [{ __typename: 'Trait', type: TraitTypeEnum.furniture }] : [],
        id: homeIndexSearchNode.id,
        favoriteMarkedByUser: isFavoriteMarkedByUser,
        user: {
          __typename: 'User',
          firstName: null,
          profilePicture: null,
          uid: 'fake-uid', // TODO: Check if faking id here is ok
          proAgent: homeIndexSearchNode.shortcutHome || false,
        },
        firsthand: homeIndexSearchNode.firstHand || false,
        instantSignSetting: {
          __typename: 'InstantSignSetting',
          id: homeIndexSearchNode.id, // NOTE: This is a fake id to make the key unique
          enabled: homeIndexSearchNode.instantSign || false,
        },
        landlord: {
          __typename: 'User',
          profilePicture: null,
          uid: 'fake-uid', // TODO: Check if faking id here is ok
          companyName: null,
          premium: false,
          professional: false,
          proPilot: false,
        },
        location: {
          __typename: 'Location',
          id: homeIndexSearchNode.location?.id?.toString() || '123',
          latitude: homeIndexSearchNode.location?.point?.lat || null,
          longitude: homeIndexSearchNode.location?.point?.lon || null,
          route: homeIndexSearchNode.location?.route || null,
          locality: homeIndexSearchNode.location?.locality || null,
          sublocality: null,
          // NOTE: CountryCode should always be available on the home
          countryCode: homeIndexSearchNode.location?.countryCode || 'SE',
          streetNumber: homeIndexSearchNode.location?.streetNumber || null,
        },
        displayStreetNumber: homeIndexSearchNode.displayStreetNumber || false,
        maximumPricePerNight: null,
        maxRent: null,
        minimumPricePerNight: null,
        maxRoomCount: null,
        maxSquareMeters: null,
        minRent: null,
        minRoomCount: null,
        minSquareMeters: null,
        numberOfHomes: null,
        rent: homeIndexSearchNode.rent,
        rentalType: HomeRentalTypeEnum.long_term,
        roomCount: homeIndexSearchNode.roomCount,
        seniorHome: homeIndexSearchNode.seniorHome || false,
        squareMeters: homeIndexSearchNode.squareMeters,
        shared: homeIndexSearchNode.shared || false,
        studentHome: homeIndexSearchNode.studentHome || false,
        tenantBaseFee: homeIndexSearchNode.tenantBaseFee,
        tenantCount: null,
        title: homeIndexSearchNode.title,
        type: homeIndexSearchNode.homeType as HomeTypeEnum,
        uploads: (homeIndexSearchNode.uploads || []).map((upload, index) => ({
          __typename: 'Upload',
          id: upload.id?.toString() || `fake-id-${homeIndexSearchNode.id}-${index}`, // TODO: Check if faking id here is ok
          url: upload.url || '',
          type: upload.type as UploadTypeEnum,
          title: '',
          metadata: {
            __typename: 'UploadMetadata',
            primary: false,
            order: upload.order,
          },
        })),
        links: [],
        shouldGetDeferredBlockListingData: homeIndexSearchNode.blockListing || false,
      }
    },
    [data?.favoriteHomes.filterHomesOffset.nodes],
  )

  return {
    homeIndexOrderDirection,
    homeIndexOrderBy,
    homeDocumentToHomeNode,
  }
}
