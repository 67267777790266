import { useCallback } from 'react'
import { useQueryParams } from 'use-query-params'
import compact from 'lodash/compact'
import {
  withDefault,
  NumberParam,
  StringParam,
  encodeArray,
  createEnumParam,
  decodeDelimitedArray,
} from 'serialize-query-params'
import { HomeRentalTypeEnum, HomeSearchOrderByEnum, HomeSearchOrderEnum } from '@qasa/graphql'
import { parseSearchAreaQuery, stringifySearchArea } from '@qasa/app/src/utils/search'

import type { SelectedArea } from '../find-home.types'
import type { FindHomeFilterValues, FindHomeFiltersContextValue } from '../context/find-home-filter-context'

const SortedStringArrayParam = {
  encode: (array?: string[]) => {
    const clonedArray = array ? [...array] : []
    return encodeArray(clonedArray.sort())
  },
  decode: (arrayStr: string | (string | null)[] | null | undefined) => {
    const decodedArray = decodeDelimitedArray(arrayStr, ',')
    return compact(decodedArray)
  },
}
const HomeRentalTypeEnumParam = createEnumParam(Object.values(HomeRentalTypeEnum))
const NullableNumberParam = withDefault(NumberParam, null)
const NullableStringParam = withDefault(StringParam, null)
const SearchAreasParam = {
  encode: (searchAreas: SelectedArea[]) => encodeArray(searchAreas.map(stringifySearchArea).filter(Boolean)),
  decode: (arrayString: string | (string | null)[] | null | undefined) => {
    const parsedValue = decodeDelimitedArray(arrayString, ',')
    if (!parsedValue) {
      return []
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.filter(Boolean).map((str) => parseSearchAreaQuery(str!))
    } else {
      return [parseSearchAreaQuery(parsedValue)]
    }
  },
}
const PageNumberParam = withDefault(NumberParam, 1)
const OrderEnumParam = withDefault(
  createEnumParam(Object.values(HomeSearchOrderEnum)),
  HomeSearchOrderEnum.DESCENDING,
)
const OrderByEnumParam = withDefault(
  createEnumParam(Object.values(HomeSearchOrderByEnum)),
  HomeSearchOrderByEnum.PUBLISHED_AT,
)
const UndefinableStringArrayParam = {
  encode: (array: string[]) => encodeArray(array),
  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    (decodeDelimitedArray(arrayStr, ',') as string[] | undefined) || undefined,
}
const paramConfig = {
  minMonthlyCost: NullableNumberParam,
  maxMonthlyCost: NullableNumberParam,
  minSquareMeters: NullableNumberParam,
  maxSquareMeters: NullableNumberParam,
  minRoomCount: NullableNumberParam,
  maxRoomCount: NullableNumberParam,
  earliestMoveIn: NullableStringParam,
  minRentalLength: NullableNumberParam,
  homeTypes: SortedStringArrayParam,
  contractTypes: SortedStringArrayParam,
  safeRental: SortedStringArrayParam,
  sharedHome: SortedStringArrayParam,
  furnished: SortedStringArrayParam,
  wheelchairAccessible: SortedStringArrayParam,
  householdSize: NullableNumberParam,
  searchAreas: SearchAreasParam,
  page: PageNumberParam,
  checkInDate: NullableStringParam,
  checkOutDate: NullableStringParam,
  bedCount: NullableNumberParam,
  bedroomCount: NullableNumberParam,
  toiletCount: NullableNumberParam,
  includedRooms: SortedStringArrayParam,
  minNightlyCost: NullableNumberParam,
  maxNightlyCost: NullableNumberParam,
  popularTraits: SortedStringArrayParam,
  locationPerks: SortedStringArrayParam,
  equipmentTraits: SortedStringArrayParam,
  rentalType: HomeRentalTypeEnumParam,
  rules: SortedStringArrayParam,
  order: OrderEnumParam,
  orderBy: OrderByEnumParam,
  uids: UndefinableStringArrayParam,
}

export function useQueryParamHomeFilters(): Omit<FindHomeFiltersContextValue, 'rentalType'> {
  const [filterValues, setFilterValues] = useQueryParams(paramConfig)

  const handleClearFilterValues = (clearedFilterKeys: (keyof FindHomeFilterValues)[]) => {
    const clearedFilters: Partial<typeof filterValues> = {}

    clearedFilterKeys.forEach((key) => (clearedFilters[key] = undefined))
    clearedFilters.page = undefined
    setFilterValues(clearedFilters, 'replaceIn')
  }

  /*
   * We remove some values if they're equal to their default as
   * this cleans up the url a bit
   */
  const sanitizeFilters = useCallback((newFilters: Partial<FindHomeFilterValues>) => {
    if (newFilters.order === HomeSearchOrderEnum.DESCENDING) {
      newFilters.order = undefined
    }
    if (newFilters.orderBy === HomeSearchOrderByEnum.PUBLISHED_AT) {
      newFilters.orderBy = undefined
    }
    return newFilters
  }, [])

  /**
   * Merges into the current query parameters
   */
  const handleUpdateFilters = useCallback(
    (changedValues: Partial<FindHomeFilterValues>, shouldResetPage = true) => {
      const sanitizedFilters = sanitizeFilters(changedValues)

      // Reset page everytime the filters change
      if (shouldResetPage) {
        sanitizedFilters.page = undefined
      }
      setFilterValues(sanitizedFilters, 'replaceIn')
    },
    [sanitizeFilters, setFilterValues],
  )

  return {
    filterValues,
    clearFilterValues: handleClearFilterValues,
    updateFilterValues: handleUpdateFilters,
  }
}
