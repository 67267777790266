import { useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker'
import type moment from 'moment'
import styled from '@emotion/styled'
import type { Moment } from 'moment'
import type { TextFieldProps } from '@qasa/qds-ui'
import { TextField } from '@qasa/qds-ui'

import '../../vendor/react-dates-styling-overrides.css'

import { formatDateToYearMonthDay } from '../../helpers/date'

const Wrapper = styled.div({
  zIndex: 1,
})

type DatepickerValue = moment.Moment | null

type DatepickerProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  onChange: (date: DatepickerValue) => void
  value: DatepickerValue
  className?: string
  onOpenStateChange?: (isOpen: boolean) => void
  isOutsideRange?: (day: Moment) => boolean
}
export function DatepickerInput({
  onChange,
  value,
  errorMessage,
  placeholder,
  label,
  className,
  isOutsideRange,
  onOpenStateChange,
}: DatepickerProps) {
  const [isDatepickerVisible, setIsDatepickerVisible] = useState(false)

  const toggleDatepicker = (toggle = !isDatepickerVisible) => {
    onOpenStateChange && onOpenStateChange(toggle)
    setIsDatepickerVisible(toggle)
  }

  return (
    <Wrapper>
      {/* TODO: make this input readOnly once this option is available in QDS to prevent console warnings */}
      <TextField
        label={label}
        placeholder={placeholder}
        isInvalid={Boolean(errorMessage)}
        errorMessage={errorMessage ?? undefined}
        value={value ? formatDateToYearMonthDay(value.toString()) : ''}
        onClick={() => toggleDatepicker()}
        className={className}
      />
      {isDatepickerVisible && (
        <SingleDatePicker
          block
          date={value}
          onDateChange={onChange}
          focused={isDatepickerVisible}
          onFocusChange={({ focused }) => toggleDatepicker(focused!)}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={isOutsideRange}
          id="datepicker"
        />
      )}
    </Wrapper>
  )
}
