import { getRootUrl } from '@qasa/app/src/configs/region-config'
import type { Region } from '@qasa/app/src/contexts/region'
import { getPath } from '@qasa/app/src/navigation'
import type { MouseEvent, PropsWithChildren } from 'react'

type DotcomHomeLinkProps = PropsWithChildren<{
  homeId: string
  homeLocale?: string
  target: string
  onMouseEnter?: VoidFunction
  onMouseLeave?: VoidFunction
  onClick?: (event: MouseEvent) => void
  draggable?: boolean
  className?: string
}>

export function DotcomHomeLink({
  children,
  homeId,
  homeLocale,
  target,
  onMouseEnter,
  onMouseLeave,
  onClick,
  draggable: isDraggable,
  className,
}: DotcomHomeLinkProps) {
  const homePath = getPath('Home', {
    id: homeId,
  })
  /**
   * TODO: The below is mostly a temporary workaround until we have a better way to determine
   * what domain a home belongs to
   */
  const SUPPORTED_LOCALES: Region[] = ['se', 'fi', 'fr']

  const lowerCaseHomeLocale = homeLocale?.toLowerCase()
  const domainLocale =
    lowerCaseHomeLocale && SUPPORTED_LOCALES.includes(lowerCaseHomeLocale as Region)
      ? (lowerCaseHomeLocale as Region)
      : 'se'
  const homeDomain = getRootUrl(domainLocale)

  return (
    <a
      href={homeDomain + homePath}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target={target}
      onClick={onClick}
      rel="noreferrer"
      draggable={isDraggable}
      className={className}
    >
      {children}
    </a>
  )
}
