import { useMutation } from '@apollo/client'
import { type SavedSearchesQuery } from '@qasa/graphql'
import { useState } from 'react'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { useToastContext } from '@qasa/app/src/contexts/toasts'
import { UPDATE_SAVED_SEARCH } from '@qasa/app/src/features/find-home/search-history/saved-searches/saved-searches.gql'

import { parseSavedSearch, useFormattedSavedSearch } from '../../saved-searches/saved-searches.utils'
import { useSearchHistoryContext } from '../search-history-context'

export type SavedSearchesListItemProps = {
  savedSearch: SavedSearchesQuery['savedSearch'][number]
  onListItemPress?: () => void
}

export const useSavedSearchesListItem = ({ savedSearch }: SavedSearchesListItemProps) => {
  const { id, searchParams, monitoring: isMonitoring } = savedSearch
  const { rentalType } = useSearchHistoryContext()
  const { t } = useAppTranslation('saved_searches')
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const searchSummary = useFormattedSavedSearch({
    savedSearch,
    rentalType,
  })
  const savedSearchFilters = parseSavedSearch({ searchParams, rentalType })

  const { addToast } = useToastContext()
  const handleError = () => addToast({ message: t('common_errors:unexpected_error') })

  const [updateSavedSearch] = useMutation(UPDATE_SAVED_SEARCH, {
    onCompleted: (response) => {
      const errors = response.updateSavedSearch?.errors
      if (errors) {
        handleError()
      } else {
        addToast({ message: t('toggle_monitoring.success_toast_message'), timeout: 2000 })
      }
    },
    onError: handleError,
    optimisticResponse: {
      __typename: 'MutationRoot',
      updateSavedSearch: {
        __typename: 'UpdateSavedSearchPayload',
        savedSearch: {
          ...savedSearch,
          monitoring: !isMonitoring,
        },
        errors: null,
      },
    },
  })
  return {
    updateSavedSearch: async () =>
      updateSavedSearch({
        variables: { savedSearchId: id, input: { monitoring: !isMonitoring } },
      }),
    savedSearchFilters,
    setIsDeleteDialogOpen,
    isDeleteDialogOpen,
    searchSummary,
    isMonitoring,
  }
}
