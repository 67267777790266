import styled from '@emotion/styled'
import { Heading, Paragraph, Stack } from '@qasa/qds-ui'

import { getCurrentLocale } from '../../translations/language-loader/language-utils'

import { useFindHomeFiltersContext } from './context/find-home-filter-context'
import { useFindHomeContext } from './context/find-home-context'

const BulletList = styled.ul({
  listStyle: 'initial',
  paddingLeft: '2rem',
})

const Container = styled.div(({ theme }) => ({
  maxWidth: theme.sizes[1024],
  marginInline: 'auto',
  paddingInline: theme.spacing['8x'],
  marginBlock: theme.spacing['16x'],
  [theme.breakpoints.md]: {
    paddingInline: theme.spacing['24x'],
    marginBlock: theme.spacing['24x'],
  },
}))

export function SeoTestText() {
  const { filterValues } = useFindHomeFiltersContext()
  const { areas } = useFindHomeContext()
  const currentLang = getCurrentLocale()
  const isStockholmSearch = areas.some((area) => area.identifier === 'se/stockholm')
  const isApartmentSearch = filterValues.homeTypes.includes('apartment')

  const shouldShowSeoText = isStockholmSearch && isApartmentSearch && currentLang === 'sv'

  if (!shouldShowSeoText) return null

  return (
    <Container>
      <Stack gap="6x">
        <Heading size="lg">
          {
            'Letar du efter lediga lägenheter i Stockholm? Låt Qasa hjälpa dig och upptäck Stockholm som bostadsort'
          }
        </Heading>
        <Paragraph size="lg">
          {
            'Stockholm, Sveriges största stad, är ett fantastiskt ställe att bo på. Med sin rika historia, pulserande kultur och livliga atmosfär lockar det människor från alla hörn av landet och utlandet. Staden grundades under 1200-talet och har sedan dess växt till att bli hem för över två miljoner invånare.'
          }
        </Paragraph>
        <Paragraph size="lg">
          {
            'När det gäller bostadslandskapet finns det något för alla - oavsett om man föredrar den historiska charmen hos Gamla stan eller den moderna elegansen hos Östermalm. Och vad är då mer spännande än möjligheten att utforska denna dynamiska stad från komforten av din egen bostad?'
          }
        </Paragraph>
        <Heading>{'Använd Qasa när du vill hyra en lägenhet i Stockholm'}</Heading>
        <Paragraph>
          {
            'Att leta efter en bostad kan vara krångligt men inte med oss på Qasa! Vi erbjuder trygghet genom vår noggranna granskning av varje hyresvärd innan kontrakt skrivs på så att ingenting blir en otrevlig överraskning. Du betalar inte heller någon deposition, vilket innebär att du inte behöver ligga ute med pengar - du betalar bara hyran varje månad.'
          }
        </Paragraph>
        <Paragraph>
          {
            'Men det slutar inte där! Qasa finns här för dig hela vägen från ansökning till slutet av din hyresperiod.'
          }
        </Paragraph>
        <Heading>
          {'Det här är de populäraste områdena när du vill hyra en ledig lägenhet i Stockholm'}
        </Heading>
        <BulletList>
          <li>{'Södermalm: Känd för sin bohemiska atmosfär och trendiga butiker.'}</li>
          <li>
            {
              'Vasastan: Populärt bland unga yrkesverksamma tack vare dess centrala läge och livliga restaurangscen.'
            }
          </li>
          <li>
            {
              'Kungsholmen: Perfekt för dem som föredrar ett lugnare tempo men fortfarande vill vara nära stadens puls.'
            }
          </li>
          <li>
            {
              'Djurgården: Omringad av natur, perfekt för den som gillar friluftsliv men ändå vill ha staden inom räckhåll.'
            }
          </li>
          <li>{'Gamla Stan: Fullt av historisk charm, kullerstensgator och mysiga caféer.'}</li>
        </BulletList>
        <Heading>{'Vad kostar det att hyra en lägenhet i Stockholm?'}</Heading>
        <Paragraph>
          {
            'Hyrorna i Stockholm kan variera beroende på flera faktorer såsom storlek på bostaden, dess plats samt skick. Men oroa dig inte – oavsett din budget finns det alltid något för dig. Det viktigaste är att du gör en noggrann sökning och jämför olika alternativ innan du bestämmer dig.'
          }
        </Paragraph>
        <Heading>{'Vad kan man göra i Stockholm?'}</Heading>
        <Paragraph>
          {
            'Stockholm erbjuder en rad spännande upplevelser! Utforska kungliga slott, promenera längs vattnet eller njut av stadens pulserande nattliv. Dessutom är staden hem till flera världsberömda museer som Vasa-museet och Moderna Museet. Oavsett dina intressen kommer du säkert att hitta något som passar just dig!'
          }
        </Paragraph>
        <Heading>{'Tänk på det här när du är på jakt efter en lägenhet i Stockholm'}</Heading>
        <Paragraph>
          {
            'När du letar efter lediga lägenheter i Stockholm bör du överväga faktorer såsom områdets karaktär, närliggande bekvämligheter samt hur långt det ligger från ditt arbete eller skola. Se även till att noggrant granska kontraktet innan undertecknandet och tveka inte att fråga om saker verkar oklara.'
          }
        </Paragraph>
        <Paragraph>
          {'Och kom ihåg - vi på Qasa finns här för att hjälpa dig genom varje steg av processen!'}
        </Paragraph>
      </Stack>
    </Container>
  )
}
