import { HomeSearchOrderByEnum, HomeSearchOrderEnum } from '@qasa/graphql'

import type { FindHomeFilterValues } from '../context/find-home-filter-context'

export const DEFAULT_FILTER_VALUES: FindHomeFilterValues = {
  minMonthlyCost: null,
  maxMonthlyCost: null,
  minSquareMeters: null,
  maxSquareMeters: null,
  minRoomCount: null,
  maxRoomCount: null,
  earliestMoveIn: null,
  minRentalLength: null,
  homeTypes: [],
  contractTypes: [],
  sharedHome: [],
  furnished: [],
  wheelchairAccessible: [],
  householdSize: null,
  searchAreas: [],
  page: 1,
  checkInDate: null,
  checkOutDate: null,
  bedCount: null,
  bedroomCount: null,
  toiletCount: null,
  includedRooms: [],
  minNightlyCost: null,
  maxNightlyCost: null,
  popularTraits: [],
  locationPerks: [],
  equipmentTraits: [],
  rentalType: null,
  rules: [],
  order: HomeSearchOrderEnum.DESCENDING,
  orderBy: HomeSearchOrderByEnum.PUBLISHED_AT,
  uids: undefined,
}
