import { HomeRentalTypeEnum } from '@qasa/graphql'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { ampliExperiment } from '@qasa/ampli/p2'
import {
  HOME_INDEX_SEARCH,
  HOME_SEARCH,
} from '@qasa/app/src/features/find-home/hooks/use-home-search/use-home-search-query.gql'
import { useRegionConfig } from '@qasa/app/src/configs/use-region-config'

import { useFindHomeFiltersContext } from '../context/find-home-filter-context'
import { useEmbeddedFindHome } from '../../../hooks/use-embedded-find-home'
import { filtersToHomeSearchParams, PAGE_SIZE, pageToOffset } from '../find-home.utils'
import type { SelectedAreaWithSearchAreaData } from '../find-home.types'

import { useMapIndexSearchToHomeSearch } from './use-home-search-query.util'

type UseHomeSearchQueryParams = {
  areas: SelectedAreaWithSearchAreaData[]
  rentalType: HomeRentalTypeEnum
}

export const useHomeSearchQuery = ({ areas, rentalType }: UseHomeSearchQueryParams) => {
  const { currency, market } = useRegionConfig()
  const { filterValues } = useFindHomeFiltersContext()
  const { homeDocumentToHomeNode, homeIndexOrderBy, homeIndexOrderDirection } =
    useMapIndexSearchToHomeSearch()

  const isEmbeddedFindHome = useEmbeddedFindHome()
  const isInLegacySearchGroup = ampliExperiment.getVariant('use-legacy-home-search')?.value === 'on'
  const isVacationSearch = rentalType === HomeRentalTypeEnum.vacation
  const shouldUseElastic = !isInLegacySearchGroup && !isVacationSearch && !isEmbeddedFindHome

  const searchParams = filtersToHomeSearchParams({
    searchParams: filterValues,
    areas,
    rentalType,
  })

  const homeSearchData = useQuery(HOME_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: shouldUseElastic,
    variables: {
      limit: PAGE_SIZE,
      market,
      offset: pageToOffset(filterValues.page, PAGE_SIZE),
      order: filterValues.order,
      orderBy: filterValues.orderBy,
      searchParams: { ...searchParams, currency },
    },
  })

  const homeIndexSearchData = useQuery(HOME_INDEX_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !shouldUseElastic,
    variables: {
      limit: PAGE_SIZE,
      offset: pageToOffset(filterValues.page, PAGE_SIZE),
      order: {
        orderBy: homeIndexOrderBy[filterValues.orderBy],
        direction: homeIndexOrderDirection[filterValues.order],
      },
      params: {
        ...searchParams,
        market,
        currency,
      },
    },
  })

  const {
    error: homesError,
    loading: isHomesLoading,
    fetchMore,
  } = shouldUseElastic ? homeIndexSearchData : homeSearchData

  const handleFetchMore = ({ page }: { page: number }) => {
    fetchMore({
      variables: {
        offset: pageToOffset(page, PAGE_SIZE),
      },
    })
  }

  const homeSearchHomes = homeSearchData.data?.homeSearch.filterHomesOffset.nodes || []
  const homeIndexSearchHomes = useMemo(
    () => homeIndexSearchData.data?.homeIndexSearch.documents.nodes.map(homeDocumentToHomeNode) || [],
    [homeIndexSearchData.data, homeDocumentToHomeNode],
  )

  const homeData = shouldUseElastic
    ? homeIndexSearchData.data?.homeIndexSearch.documents
    : homeSearchData.data?.homeSearch.filterHomesOffset

  const previousHomeData = shouldUseElastic
    ? homeIndexSearchData.previousData?.homeIndexSearch.documents
    : homeSearchData.previousData?.homeSearch.filterHomesOffset

  const homes = shouldUseElastic ? homeIndexSearchHomes : homeSearchHomes
  const hasNoResults = homeData?.totalCount === 0
  const resultCountWithFallback = homeData?.totalCount || previousHomeData?.totalCount
  const homesResultCount = hasNoResults ? 0 : resultCountWithFallback

  const totalPagesCount = homeData?.pagesCount
  const totalHomesCount = homeData?.totalCount

  return {
    homes,
    isHomesLoading,
    homesError,
    homesResultCount,
    fetchMoreHomes: handleFetchMore,
    totalPagesCount,
    totalHomesCount,
  }
}
