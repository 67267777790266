import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Stack, Heading, Button, HintBox } from '@qasa/qds-ui'
import { useToastContext } from '@qasa/app'
import { Dialog } from '@qasa/app/src/components/dialog'
import type { GetCreditReportQuery } from '@qasa/graphql'

import { DELETE_CREDIT_REPORT } from '../credit-report.gql'
import { useAuthContext } from '../../../../context/auth-context'
import { ButtonSection } from '../../components/button-section'
import { CreditVerificationSteps } from '../credit-verification-step'
import { CreditReportItem } from '../../../settings/verifications/credit-report-item'
import { DangerButton } from '../../../../ui-shared/_core/danger-button'

const Wrapper = Stack

type CreditVerificationDeleteProps = {
  onCancel: () => void
  manuallyUpdateVerificationState: (newState: CreditVerificationSteps) => void
  data?: NonNullable<GetCreditReportQuery['me']>['creditReport'] | null
}
export function CreditVerificationDelete({
  onCancel,
  manuallyUpdateVerificationState,
  data,
}: CreditVerificationDeleteProps) {
  const { t } = useTranslation('create_application_with_verifications')
  const { authBody, refreshAuthBody } = useAuthContext()
  const { addToast } = useToastContext()

  const [deleteCreditReport, { loading: isLoading }] = useMutation(DELETE_CREDIT_REPORT, {
    onCompleted: () => {
      refreshAuthBody().then(() => manuallyUpdateVerificationState(CreditVerificationSteps.CreditInitial))
    },
    onError: () => addToast({ message: t('commons:unexpected_error') }),
  })

  const handleDelete = async () => {
    if (authBody?.creditReport?.id) {
      await deleteCreditReport({ variables: { creditReportId: authBody.creditReport.id } })
    }
  }

  return (
    <>
      <Dialog.Body>
        <Wrapper gap={'8x'}>
          <Heading size="lg">{t(`credit_verification_step.delete.title`)}</Heading>
          {data && <CreditReportItem data={data} shouldHideActions />}
          <HintBox>{t('credit_verification_step.delete.delete_warning')}</HintBox>
        </Wrapper>
      </Dialog.Body>
      <ButtonSection>
        <DangerButton onClick={handleDelete} isLoading={isLoading}>
          {t('commons:delete')}
        </DangerButton>
        <Button variant="tertiary" onClick={onCancel}>
          {t(`commons:cancel`)}
        </Button>
      </ButtonSection>
    </>
  )
}
