import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { Paragraph, Link } from '@qasa/qds-ui'

import { EXTERNAL_LINKS } from '../../../config/external-links'

export function SortingLink() {
  const { t } = useAppTranslation('filter_fields')

  if (!EXTERNAL_LINKS.sortingPolicyUrl) {
    return null
  }
  return (
    <Link href={EXTERNAL_LINKS.sortingPolicyUrl}>
      <Paragraph size="sm" as="span">
        {t('filter_sections:sorting.read_more')}
      </Paragraph>
    </Link>
  )
}
