import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import moment from 'moment'

import { useFindHomeFiltersContext } from '../../../context/find-home-filter-context'

const DATE_FILTER_FORMAT = 'YYYY-MM-DD'

export const useDatesFilterSection = () => {
  const { t } = useAppTranslation('filter_fields')
  const { updateFilterValues, filterValues } = useFindHomeFiltersContext()
  const { earliestMoveIn, minRentalLength } = filterValues
  const earliestMoveInDate = earliestMoveIn ? moment(earliestMoveIn) : moment()

  const minRentalLengthOptions = [
    ...Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]).map((numberOfMonths) => {
      return {
        value: moment.duration(numberOfMonths, 'months').asSeconds(),
        label: t('filter_sections:dates.months', { count: numberOfMonths }),
      }
    }),
    {
      value: moment.duration(1, 'year').asSeconds(),
      label: t('filter_sections:dates.at_least_years', { count: 1 }),
    },
  ]

  const handleEarliestMoveInChange = (value: moment.Moment | null) => {
    updateFilterValues({ earliestMoveIn: value?.format(DATE_FILTER_FORMAT) || null })
  }
  const handleMinRentalLengthChange = (value: string) => {
    updateFilterValues({ minRentalLength: parseInt(value, 10) || null })
  }

  return {
    minRentalLengthOptions,
    handleEarliestMoveInChange,
    handleMinRentalLengthChange,
    minRentalLength,
    earliestMoveInDate,
  }
}
