import { isSameYear } from 'date-fns'
import { formatNumber } from '@qasa/app/src/utils/number'
import { formatDateToDayMonthYear, formatDayAndMonthSimplified } from '@qasa/app/src/utils/date'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'

import type { FindHomeFilterValues } from '../context/find-home-filter-context'

export function useLabelFormatters() {
  const { t } = useAppTranslation('filter_sections')

  const formatRent = ({ minMonthlyCost, maxMonthlyCost }: FindHomeFilterValues) => {
    if (minMonthlyCost && maxMonthlyCost) {
      return t('rent.range', {
        min: formatNumber({ amount: minMonthlyCost }),
        max: formatNumber({ amount: maxMonthlyCost }),
      })
    }

    if (minMonthlyCost && !maxMonthlyCost) {
      return t('rent.min', { min: formatNumber({ amount: minMonthlyCost }) })
    }

    if (!minMonthlyCost && maxMonthlyCost) {
      return t('rent.max', { max: formatNumber({ amount: maxMonthlyCost }) })
    }

    return null
  }

  const formatNightlyCost = ({ minNightlyCost, maxNightlyCost }: FindHomeFilterValues) => {
    if (minNightlyCost && maxNightlyCost) {
      return t('rent.range', {
        min: formatNumber({ amount: minNightlyCost }),
        max: formatNumber({ amount: maxNightlyCost }),
      })
    }

    if (minNightlyCost && !maxNightlyCost) {
      return t('rent.min', { min: formatNumber({ amount: minNightlyCost }) })
    }

    if (!minNightlyCost && maxNightlyCost) {
      return t('rent.max', { max: formatNumber({ amount: maxNightlyCost }) })
    }

    return null
  }

  // eslint-disable-next-line complexity
  const formatHomeSize = ({
    minRoomCount,
    maxRoomCount,
    minSquareMeters,
    maxSquareMeters,
  }: FindHomeFilterValues) => {
    if (!minRoomCount && !maxRoomCount && !minSquareMeters && !maxSquareMeters) return null

    const formattedMinMaxRooms =
      minRoomCount && maxRoomCount
        ? minRoomCount === maxRoomCount
          ? t('commons:roomWithCount', { count: minRoomCount }) // E.g: "2 rooms"
          : t('home_size.min_max_rooms', { minRoomCount, maxRoomCount }) //E.g: "1-2 rooms"
        : null

    const formattedMinRooms = minRoomCount && t('home_size.min_rooms', { count: minRoomCount }) // E.g: "3+ rooms"
    const formattedMaxRooms =
      maxRoomCount && maxRoomCount === 1
        ? t('commons:roomWithCount', { count: 1 }) // "1 room"
        : t('home_size.max_rooms', { count: maxRoomCount ?? 1 }) // E.g: "Up to 3 rooms"

    const formattedRooms = formattedMinMaxRooms ?? formattedMinRooms ?? formattedMaxRooms

    const formattedSquareMeters =
      minSquareMeters && maxSquareMeters
        ? t('home_size.min_max_sqm', { minSquareMeters, maxSquareMeters })
        : minSquareMeters
          ? t('home_size.min_sqm', { size: minSquareMeters })
          : maxSquareMeters
            ? t('home_size.max_sqm', { size: maxSquareMeters })
            : null

    return [formattedRooms, formattedSquareMeters].filter(Boolean).join(' • ')
  }

  const formatDates = ({ earliestMoveIn }: FindHomeFilterValues) => {
    if (!earliestMoveIn) {
      return null
    }

    const today = new Date()

    const isThisYear = isSameYear(new Date(earliestMoveIn), today)

    const dateFormatter = isThisYear ? formatDayAndMonthSimplified : formatDateToDayMonthYear
    const formattedDate = dateFormatter({ date: new Date(earliestMoveIn) })
    return t('dates.from', { date: formattedDate })
  }

  const formatHomeTypes = ({ homeTypes, sharedHome }: FindHomeFilterValues) => {
    if (!sharedHome.length && !homeTypes.length) {
      return null
    }

    const formattedValues: string[] = []

    if (sharedHome.includes('privateHome')) {
      formattedValues.push(t('home_type.private'))
    }
    if (sharedHome.includes('sharedHome')) {
      formattedValues.push(t('home_type.shared'))
    }
    if (homeTypes.length) {
      homeTypes.forEach((homeType) => formattedValues.push(t(`common_home:home_type.${homeType}`)))
    }

    const firstFormattedValue = formattedValues[0]
    const restFilterCount = formattedValues.length - 1
    const hasMoreFilters = restFilterCount >= 1

    return hasMoreFilters
      ? t('home_type.filter_with_count', { filter: firstFormattedValue, count: restFilterCount })
      : firstFormattedValue
  }

  return {
    formatRent,
    formatNightlyCost,
    formatHomeSize,
    formatDates,
    formatHomeTypes,
  }
}
