import { useRegionConfig } from '@qasa/app/src/configs/use-region-config'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { formatNumber } from '@qasa/app/src/utils/number'
import { useState, useEffect } from 'react'
import { RangeSlider } from '@qasa/app/src/components/range-slider'

import { useFindHomeFiltersContext } from '../../../context/find-home-filter-context'

export function RentFilterSection() {
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()
  const { currency } = useRegionConfig()
  const defaultMinMonthlyCost = 0
  const defaultMaxMonthlyCost = currency === 'EUR' ? 3_000 : 30_000
  const stepSize = currency === 'EUR' ? 10 : 100
  const { t } = useAppTranslation('filter_sections')

  const [sliderValue, setSliderValue] = useState([filterValues.minMonthlyCost, filterValues.maxMonthlyCost])

  // If value changes by clicking the clear button we need to manually update the slider value
  useEffect(() => {
    setSliderValue([filterValues.minMonthlyCost, filterValues.maxMonthlyCost])
  }, [filterValues.minMonthlyCost, filterValues.maxMonthlyCost])

  const value = [sliderValue[0] || defaultMinMonthlyCost, sliderValue[1] || defaultMaxMonthlyCost] as [
    number,
    number,
  ]

  const handleValueCommit = (newValue: number[]) => {
    const [minCost, maxCost] = newValue
    const sanitizedMin = minCost === defaultMinMonthlyCost ? null : minCost
    const sanitizedMax = maxCost === defaultMaxMonthlyCost ? null : maxCost

    updateFilterValues({ minMonthlyCost: sanitizedMin, maxMonthlyCost: sanitizedMax })
  }

  const handleFormatValue = (value: number) => {
    const formattedValue = formatNumber({ amount: value, currency })
    if (value === defaultMaxMonthlyCost) {
      return formattedValue + '+'
    }
    return formattedValue
  }
  return (
    <RangeSlider
      value={value}
      label={t('rent.monthly_cost')}
      onValueChange={setSliderValue}
      onValueCommit={handleValueCommit}
      step={stepSize}
      min={defaultMinMonthlyCost}
      max={defaultMaxMonthlyCost}
      formatValue={handleFormatValue}
      minRange={10}
    />
  )
}
