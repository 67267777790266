import { BellIcon, BellOffIcon, Divider, Heading, Stack } from '@qasa/qds-ui'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { SlashSeparatedData } from '@qasa/app/src/components/slash-separated-data'
import { PillButton } from '@qasa/app/src/components/pill-button'

import { useFindHomeFiltersContext } from '../context/find-home-filter-context'

import { useFormattedFilterValues } from './saved-searches.utils'

type Props = {
  isMonitoring: boolean
  onIsMonitoringChanged: (isMonitoring: boolean) => void
}

export function CreateSavedSearchDialogContent({ isMonitoring, onIsMonitoringChanged }: Props) {
  const { t } = useAppTranslation('saved_searches')
  const { filterValues, rentalType } = useFindHomeFiltersContext()
  const currentSearch = useFormattedFilterValues({ filterValues, rentalType })
  const Bell = isMonitoring ? BellIcon : BellOffIcon
  return (
    <Stack gap="6x">
      <SlashSeparatedData gap="2x" data={currentSearch} />
      <Divider />
      <Stack gap="2x" direction="row" alignItems="center">
        <Heading size="2xs">{t('create.monitoring_section.title')}</Heading>
        <Bell size={20} />
      </Stack>
      <Stack gap="2x" direction="row">
        <PillButton type="button" isActive={isMonitoring} onPress={() => onIsMonitoringChanged(true)}>
          {t('create.monitoring_section.button_label_yes')}
        </PillButton>
        <PillButton type="button" isActive={!isMonitoring} onPress={() => onIsMonitoringChanged(false)}>
          {t('create.monitoring_section.button_label_no')}
        </PillButton>
      </Stack>
    </Stack>
  )
}
