import { forwardRef } from 'react'
import {
  Button,
  Divider,
  Heading,
  Label,
  Stack,
  useBreakpointValue,
  IconButton,
  SlidersIcon,
} from '@qasa/qds-ui'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { Dialog } from '@qasa/app/src/components/dialog'
import styled from '@emotion/styled'

import { useFindHomeContext } from '../context/find-home-context'

import {
  useMoreFiltersDialog,
  type MoreFiltersDialogProps,
  type MoreFiltersDialogTriggerProps,
} from './use-more-filters-dialog'
import { PillDropdownButton } from './components/pill-dropdown-button'
import { SortingLink } from './sorting-link'

const Wrapper = styled.div({
  position: 'relative',
})
const ActiveFiltersBadge = styled.div(({ theme }) => ({
  position: 'absolute',
  right: -2,
  top: -2,
  ...theme.typography.caption.sm,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 18,
  width: 18,
  borderRadius: theme.radii.full,
  color: theme.colors.text.onBrandSecondary,
  backgroundColor: theme.colors.bg.brandSecondary,
}))

const MoreFiltersDialogTrigger = forwardRef<HTMLButtonElement, MoreFiltersDialogTriggerProps>(
  ({ activeFilterCount, ...props }, forwardedRef) => {
    const { t } = useAppTranslation('filter_sections')
    const isMdOrAbove = useBreakpointValue({ base: false, md: true })
    return (
      <Wrapper>
        {isMdOrAbove ? (
          <Button ref={forwardedRef} variant="tertiary" size="lg" iconLeft={SlidersIcon} {...props}>
            {t('filters')}
          </Button>
        ) : (
          <IconButton
            ref={forwardedRef}
            icon={SlidersIcon}
            label={t('filters')}
            variant="tertiary"
            aria-label={t('filters_dialog_trigger_aria_label')}
            {...props}
          />
        )}
        {Boolean(activeFilterCount) && <ActiveFiltersBadge>{activeFilterCount}</ActiveFiltersBadge>}
      </Wrapper>
    )
  },
)

const MoreFiltersDialogHeader = styled(Dialog.Header)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.border.default}`,
  paddingBlock: 0,
  marginBottom: theme.sizes['6x'],
  justifyContent: 'center',
  [theme.mediaQueries.mdUp]: { paddingBlock: 0 }, // Override padding for medium screens and up
}))

const MoreFiltersDialogFooter = styled(Dialog.Footer)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingInline: theme.spacing['6x'],
  [theme.mediaQueries.mdUp]: {
    paddingInline: theme.spacing['4x'],
  },
}))

export function MoreFiltersDialog({ filterSections }: MoreFiltersDialogProps) {
  const {
    clearFilterValues,
    hasActiveFilters,
    activeFilterCount,
    isOpen,
    closeDialog,
    handleDialogChange,
    homesResultCountLabel,
  } = useMoreFiltersDialog({ filterSections })
  const { t } = useAppTranslation('filter_sections')
  const { rentalType } = useFindHomeContext()
  const isMdOrAbove = useBreakpointValue({ base: false, md: true })

  const triggerButton =
    rentalType === HomeRentalTypeEnum.long_term ? (
      <MoreFiltersDialogTrigger activeFilterCount={activeFilterCount} />
    ) : (
      <PillDropdownButton
        size={isMdOrAbove ? 'medium' : 'small'}
        isActive={hasActiveFilters || isOpen}
        isOpen={isOpen}
      >
        <Label size="sm" as="span">
          {t('more_filters')}
        </Label>
      </PillDropdownButton>
    )

  return (
    <Dialog isOpen={isOpen} onOpenChange={handleDialogChange} trigger={triggerButton}>
      <MoreFiltersDialogHeader hasSidePadding>
        <Dialog.Title size={'2xs'}>{t('more_filters')}</Dialog.Title>
      </MoreFiltersDialogHeader>
      <Dialog.Body>
        <Stack gap="8x" divider={<Divider />}>
          {filterSections.map(({ name, Component }) => {
            const sectionTitle = t(`${name}.title`)
            return (
              <Stack key={name} gap="6x">
                <Heading size="sm" as="h3">
                  {sectionTitle}
                </Heading>
                <Component />
              </Stack>
            )
          })}
          <SortingLink />
        </Stack>
      </Dialog.Body>
      <MoreFiltersDialogFooter>
        <Button variant="tertiary" isDisabled={!hasActiveFilters} onClick={clearFilterValues}>
          {t('clear_all_filters')}
        </Button>
        <Button onClick={closeDialog}>{homesResultCountLabel}</Button>
      </MoreFiltersDialogFooter>
    </Dialog>
  )
}
