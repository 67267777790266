import type { HomeRentalTypeEnum } from '@qasa/graphql'
import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'

import { useHomeSearchQuery } from '../hooks/use-home-search-query'
import { useSearchAreaQuery } from '../hooks/use-search-area-query'

import { useFindHomeFiltersContext } from './find-home-filter-context'

type UseHomeSearchQueryReturn = ReturnType<typeof useHomeSearchQuery>
type UseSearchAreaQueryReturn = ReturnType<typeof useSearchAreaQuery>
type FindHomeContextValue = UseHomeSearchQueryReturn &
  UseSearchAreaQueryReturn & { rentalType: HomeRentalTypeEnum }

const FindHomeContext = createContext<FindHomeContextValue | undefined>(undefined)

type FindHomeProviderProps = {
  children: ReactNode
  rentalType: HomeRentalTypeEnum
}
export function FindHomeProvider({ children, rentalType }: FindHomeProviderProps) {
  const { filterValues } = useFindHomeFiltersContext()
  const searchAreaQuery = useSearchAreaQuery({ filterValues })
  const homeSearchQuery = useHomeSearchQuery({ areas: searchAreaQuery.areas, rentalType })

  return (
    <FindHomeContext.Provider value={{ rentalType, ...searchAreaQuery, ...homeSearchQuery }}>
      {children}
    </FindHomeContext.Provider>
  )
}

export const useFindHomeContext = () => {
  const context = useContext(FindHomeContext)

  if (context === undefined) {
    throw Error('`useFindHomeContext` must be used within a <FindHomeProvider>')
  }
  return context
}
