import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { Checkbox } from '@qasa/ui'

import { useFindHomeFiltersContext } from '../../context/find-home-filter-context'

export function AccessibilityFilterSection() {
  const { t } = useAppTranslation('filter_fields')
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()
  return (
    <Checkbox
      label={t('accessibility.wheelchair_accessible')}
      // We should really add more stuff into this category (e.g. elevator)...
      // Will keep it as an array for now
      isChecked={filterValues.wheelchairAccessible.includes('wheelchairAccessible')}
      onCheckedChange={(isChecked) =>
        updateFilterValues({ wheelchairAccessible: isChecked ? ['wheelchairAccessible'] : [] })
      }
    />
  )
}
